<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "FiveSimpleHabitsToBoostYourWriting",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="FiveSimpleHabitsToBoostYourWriting">
      <SuawParagraph
        text="The beginning of a new year always seems to be magical time, a time when we reach for what we want most. I want to share some simple habits to help you capture that energy and bring it into the year as a whole to really kickstart your writing."
      />
      <SuawHeading level="3" content="1. Add active observation in your daily routine" />
      <SuawParagraph
        text="It’s normal to become inured with our day-to-day routines, but pause, take a breath, make a point to observe what’s happening around you. You’ll find you see things that you’ve never noticed before. That’s where our wonder creeps in. See where your mind wanders, what you start to imagine. Take mental note of the details… And if you’re looking for some extra practice, write them down! (You keep a notebook with you, right?)"
      />
      <SuawHeading level="3" content="2. Create your writing ritual" />
      <SuawParagraph
        text="Rituals are powerful tools. Create a writing ritual for yourself takes advantage of your brain’s ability to recognize patterns. When you repeat your ritual over and over, you literally train your brain! And when you sit down to write, it becomes easier because your brain recognizes that it’s time to write."
      />
      <SuawParagraph
        text="A ritual can be anything, but one thing I suggest is to pick a specific location. Having a place where you exclusively do your writing tells your brain, “When I’m here, I’m writing.” It can be desk or specific coffee shop, or something as simple as the opposite end of the dinner table from where you usually eat. Just like an altar, your creative juices will start to really flow as your relationship with that place strengthens."
      />
      <SuawParagraph
        text="Maybe you write with coffee, first thing in the morning. Maybe you listen to a specific playlist. Find what works for you. You can borrow specifics from other writers, but put something new into it. Personalizing your writing ritual will built that connection and make you more likely to stick with it."
      />
      <SuawHeading level="3" content="3. Learn when to rest your writing" />
      <SuawParagraph
        text="This can be hard for some of us, especially if you’re on a deadline, but sometimes the best thing for your writing is to set it aside for a day, or week, or more. Giving yourself the space to return to your writing and look at it with fresh eyes can be especially helpful, particularly if you don’t have a second set of eyes lined up."
      />
      <SuawParagraph
        text="In the meantime take a walk, go see some art, live life outside of the office for a bit. Get out there and observe the world. It’ll really help your writing."
      />
      <SuawHeading level="3" content="4. Add a little more time to your writing practice" />
      <SuawParagraph
        text="Start by adding an extra 5-10 minutes to your practice. Then, once that becomes the new normal, add a little more. Soon, you may find you’re writing an additional 30 minutes to an hour. It’s amazing how quickly those extra minutes will add to your word count. By doing this small incremental change, whenever you can, you’ll see real progress in your work."
      />
      <SuawHeading level="3" content="5. Put your writing time in your calendar" />
      <SuawParagraph
        text="If you haven’t done this already, do it! You are statistically more likely to do something if you write it down. Just like with your writing ritual, this tells your brain that this is important to you, that it needs to pay attention. Be as specific in your calendar. Put down the time, location, duration of your session. Your brain will pick up the specificity and help you remember."
      />
      <SuawParagraph
        text="Also, set up some support structures for yourself. Set a reminder on your phone. Negotiate with your family to make sure they know that this will not be the time to ask you about grocery list items. If you do all this work ahead of time, it becomes much easier to get your writing done."
      />
      <SuawParagraph
        text="So, whatever you’re writing, a novel, memoir, poem, screenplay, or some technical writing, we want you to have the best writing year yet. And we hope you’ll come write with us."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
